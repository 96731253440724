define("discourse/plugins/discourse-ratings-custom-version/discourse/components/star-rating", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "span",
    classNames: "star-rating",
    stars: [1, 2, 3, 4, 5],
    enabled: false
  });
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/helpers/creation-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(isoDate) {
    const date = new Date(isoDate);
    const now = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    const diffYears = now.getFullYear() - date.getFullYear();
    const diffMonths = now.getMonth() - date.getMonth() + diffYears * 12;
    const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    let timeAgo;
    if (diffYears > 0) {
      timeAgo = `${diffYears} year${diffYears > 1 ? "s" : ""} ago`;
    } else if (diffMonths > 0) {
      timeAgo = `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
    } else {
      timeAgo = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    }
    return `${formattedDate}, ${timeAgo}`;
  }
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/connectors/topic-category/topic-tip-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.rating_enabled && ctx.siteSettings.rating_show_topic_tip;
    }
  };
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/components/rating-object", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/models/category", "I18n"], function (_exports, _component, _decorators, _computed, _category, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":rating-object", ":admin-ratings-list-object", "error:hasError"],
    tagName: "tr",
    isCategory: (0, _computed.equal)("objectType", "category"),
    isTag: (0, _computed.equal)("objectType", "tag"),
    error: null,
    didReceiveAttrs() {
      const object = this.object;
      this.setProperties({
        currentName: object.name,
        currentTypes: object.types
      });
      if (object.name) {
        if (this.isCategory) {
          const slugPath = object.name.split("/");
          this.set("category", _category.default.findBySlugPath(slugPath));
        }
        if (this.isTag) {
          this.set("tag", object.name);
        }
      }
    },
    saveDisabled(error, objectName, objectTypes) {
      return error || !objectName || !objectTypes.length || objectName === this.currentName && JSON.stringify(objectTypes) === JSON.stringify(this.currentTypes);
    },
    actions: {
      updateCategory(categoryId) {
        const category = _category.default.findById(categoryId);
        const slug = _category.default.slugFor(category);
        const objects = this.objects || [];
        if (objects.every(o => o.name !== slug)) {
          this.setProperties({
            "object.name": slug,
            category,
            error: null
          });
        } else {
          this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
            objectType: this.objectType
          }));
        }
      },
      updateTag(tags) {
        const objects = this.objects || [];
        const tag = tags[0];
        if (objects.every(o => o.name !== tag)) {
          this.setProperties({
            "object.name": tag,
            tag,
            error: null
          });
        } else {
          this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
            objectType: this.objectType
          }));
        }
      }
    }
  }, [["method", "saveDisabled", [(0, _decorators.default)("error", "object.name", "object.types.[]")]]]));
});
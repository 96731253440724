define("discourse/plugins/discourse-ratings-custom-version/discourse/components/select-rating", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["rating-container"],
    removeOnUncheck() {
      if (!this.rating.include) {
        this.set("rating.value", 0);
        this.updateRating(this.rating);
      }
    },
    actions: {
      updateRating() {
        this.updateRating(this.rating);
      }
    }
  }, [["method", "removeOnUncheck", [(0, _decorators.observes)("rating.include")]]]));
});
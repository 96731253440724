define("discourse/plugins/discourse-ratings-custom-version/discourse/helpers/round-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(number) {
    return Math.round(number * 100) / 100;
  }
});
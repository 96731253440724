define("discourse/plugins/discourse-ratings-custom-version/discourse/components/rating-destroy", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings-custom-version/discourse/models/rating", "@ember/component"], function (_exports, _decorators, _rating, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["admin-ratings-destroy", "rating-action"],
    destroyDisabled(categoryId, type) {
      return [categoryId, type].any(i => !i);
    },
    actions: {
      destroyRatings() {
        let data = {
          category_id: this.categoryId
        };
        this.set("startingDestroy", true);
        _rating.default.destroy(this.type, data).then(result => {
          if (result.success) {
            this.set("destroyMessage", "admin.ratings.destroy.started");
          } else {
            this.set("destroyMessage", "admin.ratings.error.destroy_failed_to_start");
          }
        }).finally(() => this.set("startingDestroy", false));
      }
    }
  }, [["method", "destroyDisabled", [(0, _decorators.default)("categoryId", "type")]]]));
});
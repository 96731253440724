define("discourse/plugins/discourse-ratings-custom-version/discourse/templates/connectors/topic-footer-main-buttons-before-create/rate-seller", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{rate-seller topic=this.topic}}
  */
  {
    "id": "954FghyD",
    "block": "[[[1,[28,[35,0],null,[[\"topic\"],[[30,0,[\"topic\"]]]]]]],[],false,[\"rate-seller\"]]",
    "moduleName": "discourse/plugins/discourse-ratings-custom-version/discourse/templates/connectors/topic-footer-main-buttons-before-create/rate-seller.hbs",
    "isStrictMode": false
  });
});
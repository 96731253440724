define("discourse/plugins/discourse-ratings-custom-version/discourse/widgets/star-rating", ["exports", "discourse/widgets/widget", "virtual-dom"], function (_exports, _widget, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("star-rating", {
    tagName: "span.star-rating",
    html(attrs) {
      const stars = [1, 2, 3, 4, 5];
      let contents = [];
      stars.forEach(s => {
        let checked = s <= attrs.rating;
        contents.push(this.attach("rating-star", {
          value: s,
          checked,
          disabled: attrs.disabled
        }), (0, _virtualDom.h)("i"));
      });
      return contents;
    }
  });
});
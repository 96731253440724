define("discourse/plugins/discourse-ratings-custom-version/discourse/user-rating-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    map() {
      this.route("reviews", {
        resetNamespace: true
      });
      this.route("buyers", {
        resetNamespace: true
      });
    }
  };
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/components/review-star-rating", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "span",
    classNames: ["review-star-rating"],
    stars: (0, _object.computed)("value", function () {
      let stars = [];
      for (let i = 1; i <= 5; i++) {
        stars.push({
          filled: i <= this.value
        });
      }
      return stars;
    })
  });
});
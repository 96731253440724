define("discourse/plugins/discourse-ratings-custom-version/discourse/routes/reviews", ["exports", "discourse/routes/discourse", "rsvp", "discourse/plugins/discourse-ratings-custom-version/discourse/models/reviews"], function (_exports, _discourse, _rsvp, _reviews) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      if (!this.currentUser) {
        return {
          error: "not_logged_in"
        };
      }
      const user = this.modelFor("user");
      const settings = this.siteSettings;
      return (0, _rsvp.all)([_reviews.default.buildUserProps(user, settings), _reviews.default.list(user.username)]);
    },
    setupController(controller, model) {
      if (model.error) {
        controller.setProperties({
          error: model.error
        });
      } else {
        controller.setProperties({
          userProps: model[0],
          user: model[1].user,
          review: model[1],
          reviewTopics: model[1].review_topics,
          feedback_ratings: model[1].feedback_ratings,
          member_since: model[1].member_since,
          overall_rating: model[1].overall_rating,
          feedback_ratings_summary: model[1].feedback_ratings_summary,
          member_since: model[1].member_since
        });
      }
    },
    actions: {
      refreshRoute() {
        this.refresh();
      }
    }
  });
});
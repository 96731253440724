define("discourse/plugins/discourse-ratings-custom-version/discourse/components/modal/confirmation-dialog", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <!-- app/templates/components/confirmation-dialog.hbs -->
  {{#if this.dialog.isVisible}}
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmationDialogLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmationDialogLabel">{{if
                this.dialog.showError
                "Error"
                "Confirm"
              }}</h5>
            <button
              type="button"
              class="close"
              aria-label="Close"
              {{on "click" this.dialog.close}}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{this.dialog.message}}
          </div>
          <div class="modal-footer">
            {{#if this.dialog.showError}}
              <button
                type="button"
                class="btn btn-secondary"
                {{on "click" this.dialog.close}}
              >Close</button>
            {{else}}
              <button
                type="button"
                class="btn btn-secondary"
                {{on "click" this.dialog.close}}
              >Cancel</button>
              <button
                type="button"
                class="btn btn-primary"
                {{on "click" this.dialog.confirm}}
              >Yes</button>
            {{/if}}
          </div>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "3/4tTrQK",
    "block": "[[[3,\" app/templates/components/confirmation-dialog.hbs \"],[1,\"\\n\"],[41,[30,0,[\"dialog\",\"isVisible\"]],[[[1,\"  \"],[10,0],[14,0,\"modal show\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"dialog\"],[14,\"aria-labelledby\",\"confirmationDialogLabel\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-dialog\"],[14,\"role\",\"document\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n          \"],[10,\"h5\"],[14,0,\"modal-title\"],[14,1,\"confirmationDialogLabel\"],[12],[1,[52,[30,0,[\"dialog\",\"showError\"]],\"Error\",\"Confirm\"]],[13],[1,\"\\n          \"],[11,\"button\"],[24,0,\"close\"],[24,\"aria-label\",\"Close\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"dialog\",\"close\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n          \"],[1,[30,0,[\"dialog\",\"message\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[41,[30,0,[\"dialog\",\"showError\"]],[[[1,\"            \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"dialog\",\"close\"]]],null],[12],[1,\"Close\"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"dialog\",\"close\"]]],null],[12],[1,\"Cancel\"],[13],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"dialog\",\"confirm\"]]],null],[12],[1,\"Yes\"],[13],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-ratings-custom-version/discourse/components/modal/confirmation-dialog.hbs",
    "isStrictMode": false
  });
  // app/components/confirmation-dialog.js

  class ConfirmationDialogComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  }
  _exports.default = ConfirmationDialogComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfirmationDialogComponent);
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/models/reviews", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Reviews = _object.default.extend({});
  Reviews.reopenClass({
    list(username) {
      return (0, _ajax.ajax)(`/u/${username}/reviews.json`).then(_ref => {
        let {
          review
        } = _ref;
        return review;
      }).catch(_ajaxError.popupAjaxError);
    },
    buildUserProps(user, settings) {
      const props = {
        id: user.id,
        avatar: user.avatar_template.replace("{size}", "75"),
        bio: user.bio_cooked,
        featured_topic: user.featured_topic,
        name: user.name,
        username: user.username,
        profile_path: user.path,
        banner: user.profile_background_upload_url
      };
      return props;
    }
  });
  var _default = _exports.default = Reviews;
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/controllers/buyers", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    review: null,
    reviewTopics: null,
    init() {
      this._super(...arguments);
    },
    avatarSource(avatarTemplate) {
      return avatarTemplate ? avatarTemplate.replace("{size}", "75") : "";
    },
    avatarSrc(avatarTemplate) {
      return avatarTemplate ? avatarTemplate.replace("{size}", "75") : "";
    }
  }, [["method", "avatarSrc", [(0, _decorators.default)("review.user.avatar_template")]]]));
});
define("discourse/plugins/discourse-ratings-custom-version/discourse/initializers/initialize-ratings", ["exports", "discourse/models/composer", "discourse/models/category", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-ratings-custom-version/discourse/lib/rating-utilities", "I18n", "handlebars", "@ember/application", "@ember/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "@ember/service", "@ember/runloop", "discourse/plugins/discourse-ratings-custom-version/discourse/components/modal/rate-error", "discourse/lib/url"], function (_exports, _composer, _category, _pluginApi, _decorators, _computed, _ratingUtilities, _I18n, _handlebars, _application, _object, _environment, _debounce, _service, _runloop, _rateError, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-ratings-custom-version";
  var _default = _exports.default = {
    dialog: (0, _service.inject)(),
    name: "initialize-ratings",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.rating_enabled) {
        return;
      }
      _composer.default.serializeOnCreate("ratings", "ratingsString");
      _composer.default.serializeOnUpdate("ratings", "ratingsString");
      _composer.default.serializeToDraft("ratings", "ratingsString");
      (0, _pluginApi.withPluginApi)("0.10.0", api => {
        const currentUser = api.getCurrentUser();
        api.includePostAttributes("ratings");
        api.decorateWidget("poster-name:after", function (helper) {
          const post = helper.getModel();
          if (post && post.topic && post.topic.show_ratings && post.ratings) {
            return helper.rawHtml(`${new _handlebars.default.SafeString((0, _ratingUtilities.ratingListHtml)(post.ratings))}`);
          }
        });
        api.reopenWidget("poster-name", {
          buildClasses() {
            const post = this.findAncestorModel();
            let classes = [];
            if (post && post.topic && post.topic.show_ratings && post.ratings) {
              classes.push("has-ratings");
            }
            return classes;
          }
        });
        api.modifyClass("model:composer", dt7948.p({
          pluginId: PLUGIN_ID,
          editingPostWithRatings: (0, _computed.and)("editingPost", "post.ratings.length"),
          hasRatingTypes: (0, _computed.notEmpty)("ratingTypes"),
          showRatings: (0, _computed.or)("hasRatingTypes", "editingPostWithRatings"),
          ratingTypes(editingPostWithRatings, topicFirstPost, postRatings, allowedRatingTypes) {
            let userCanRate;
            if (this.topic) {
              userCanRate = this.topic.user_can_rate;
            }
            let types = [];
            if (editingPostWithRatings) {
              types.push(...postRatings.map(r => r.type));
            }
            if (topicFirstPost && allowedRatingTypes.length) {
              allowedRatingTypes.forEach(t => {
                if (types.indexOf(t) === -1) {
                  types.push(t);
                }
              });
            } else if (userCanRate && userCanRate.length) {
              userCanRate.forEach(t => {
                if (types.indexOf(t) === -1) {
                  types.push(t);
                }
              });
            }
            return types;
          },
          ratings: (0, _object.computed)("ratingTypes", "editingPostWithRatings", "post.ratings", {
            get() {
              const typeNames = this.site.rating_type_names;
              let result = this.ratingTypes.map(type => {
                let currentRating = (this.post && this.post.ratings || []).find(r => r.type === type);
                let value;
                let include;
                if (this.editingPostWithRatings && currentRating) {
                  value = currentRating.value;
                  include = currentRating.weight > 0 ? true : false;
                }
                let rating = {
                  type,
                  value,
                  include: include !== null ? include : true
                };
                if (typeNames && typeNames[type]) {
                  rating.typeName = typeNames[type];
                }
                return rating;
              });
              return result;
            },
            set(key, value) {
              const typeNames = this.site.rating_type_names;
              let result = this.ratingTypes.map(type => {
                let currentRating = (value || []).find(r => r.type === type);
                let score;
                let include;
                if (this.hasRatingTypes && currentRating) {
                  score = currentRating.value;
                  include = currentRating.value > 0 ? true : false;
                }
                let rating = {
                  type,
                  value: score,
                  include: include !== null ? include : true
                };
                if (typeNames && typeNames[type]) {
                  rating.typeName = typeNames[type];
                }
                return rating;
              });
              return result;
            }
          }),
          allowedRatingTypes(tags, category) {
            const site = this.site;
            let types = [];
            if (category) {
              const categoryTypes = site.category_rating_types[_category.default.slugFor(category)];
              if (categoryTypes) {
                types.push(...categoryTypes);
              }
            }
            if (tags) {
              const tagTypes = site.tag_rating_types;
              if (tagTypes) {
                tags.forEach(t => {
                  if (tagTypes[t]) {
                    types.push(...tagTypes[t]);
                  }
                });
              }
            }
            return types;
          },
          ratingsToSave(ratings) {
            return ratings.map(r => ({
              type: r.type,
              value: r.value,
              weight: r.include ? 1 : 0
            }));
          },
          ratingsString: (0, _object.computed)("ratingsToSave.@each.{value}", {
            get() {
              return JSON.stringify(this.ratingsToSave);
            },
            set(key, value) {
              if (value) {
                const typeNames = this.site.rating_type_names;
                const draftRatings = JSON.parse(value).map(r => {
                  return {
                    type: r.type,
                    value: r.value,
                    typeName: typeNames[r.type],
                    include: true
                  };
                });
                this.set("ratings", draftRatings);
              }
              let result = value || JSON.stringify(this.ratingsToSave);
              return result;
            }
          })
        }, [["method", "ratingTypes", [(0, _decorators.default)("editingPostWithRatings", "topicFirstPost", "post.ratings", "allowedRatingTypes.[]")]], ["method", "allowedRatingTypes", [(0, _decorators.default)("tags", "category")]], ["method", "ratingsToSave", [(0, _decorators.default)("ratings.@each.{value}")]]]));
        api.modifyClass("controller:composer", dt7948.p({
          pluginId: PLUGIN_ID,
          save(ignore, event) {
            const model = this.model;
            const ratings = model.ratings;
            const showRatings = model.showRatings;
            if (showRatings && ratings.some(r => r.include && !r.value)) {
              return this.dialog.alert(_I18n.default.t("composer.select_rating"));
            }
            return this._super(ignore, event);
          },
          _shouldSaveDraft() {
            if (this.model && !this.model.loading && !this.skipAutoSave && !this.model.disableDrafts) {
              if (!this._lastDraftSaved) {
                // pretend so we get a save unconditionally in 15 secs
                this._lastDraftSaved = Date.now();
              }
              if (Date.now() - this._lastDraftSaved > 15000) {
                this._saveDraft();
              } else {
                let method = (0, _environment.isTesting)() ? _runloop.run : _debounce.default;
                this._saveDraftDebounce = method(this, this._saveDraft, 2000);
              }
            }
          }
        }, [["method", "_shouldSaveDraft", [(0, _decorators.observes)("model.reply", "model.title", "model.ratings.@each.{value}")]]]));
        api.registerCustomPostMessageCallback("ratings", (controller, data) => {
          const model = controller.get("model");
          const typeNames = controller.site.rating_type_names;
          data.ratings.forEach(r => {
            if (typeNames && typeNames[r.type]) {
              r.type_name = typeNames[r.type];
            }
          });
          model.set("ratings", data.ratings);
          model.get("postStream").triggerChangedPost(data.id, data.updated_at).then(() => {
            controller.appEvents.trigger("post-stream:refresh", {
              id: data.id
            });
          });
          if (data.user_id === currentUser.id) {
            model.set("user_can_rate", data.user_can_rate);
          }
          controller.appEvents.trigger("header:update-topic", model);
        });
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: PLUGIN_ID,
          hasRatings: (0, _computed.and)("topic.show_ratings", "topic.ratings"),
          unboundClassNames(topic, lastVisitedTopic, hasRatings) {
            let classes = this._super(topic, lastVisitedTopic) || "";
            if (hasRatings) {
              classes += " has-ratings";
            }
            return classes;
          }
        }, [["method", "unboundClassNames", [(0, _decorators.default)("topic", "lastVisitedTopic", "hasRatings")]]]));
        api.modifyClass("component:topic-title", dt7948.p({
          pluginId: PLUGIN_ID,
          hasRatings: (0, _computed.alias)("model.show_ratings"),
          editing: (0, _computed.alias)("topicController.editingTopic"),
          hasTags: (0, _computed.notEmpty)("model.tags"),
          showTags: (0, _computed.and)("hasTags", "siteSettings.tagging_enabled"),
          hasFeaturedLink: (0, _computed.notEmpty)("model.featured_link"),
          showFeaturedLink: (0, _computed.and)("hasFeaturedLink", "siteSettings.topic_featured_link_enabled"),
          hasExtra: (0, _computed.or)("showTags", "showFeaturedLink"),
          classNameBindings: ["hasRatings", "editing", "hasExtra"],
          setupController() {
            const topicController = (0, _application.getOwner)(this).lookup("controller:topic");
            this.set("topicController", topicController);
          }
        }, [["method", "setupController", [(0, _decorators.on)("init")]]]));
        api.modifyClass("component:composer-body", dt7948.p({
          pluginId: PLUGIN_ID,
          resizeIfShowRatings() {
            if (this.get("composer.viewOpen")) {
              this._triggerComposerResized();
            }
          },
          addContainerClass() {
            if (!this.element || this.isDestroying || this.isDestroyed) {
              return;
            }
            if (this.composer && this.composer.showRatings) {
              if (!this.element.classList.contains("reply-control-ratings")) {
                this.element.classList.add("reply-control-ratings");
              }
            }
          }
        }, [["method", "resizeIfShowRatings", [(0, _decorators.observes)("composer.showRatings")]], ["method", "addContainerClass", [(0, _decorators.on)("didRender")]]]));
        api.composerBeforeSave(function () {
          const composerController = Discourse.__container__.lookup("controller:composer");
          const composerModel = composerController.get("model");
          const categoryId = composerModel.categoryId;
          if (categoryId === siteSettings.seller_category) {
            const content = composerModel.reply;
            const seller = composerModel.title.match(/@(\w+)/)[1];
            const username = composerModel.user.username;
            const ratings = composerModel.ratingsString;
            return new Promise((resolve, reject) => {
              $.ajax({
                type: "POST",
                url: "/rate_seller",
                data: {
                  username,
                  content,
                  ratings,
                  seller,
                  check_existing: true // Add this parameter to check for existing reviews
                }
              }).then(response => {
                if (response.exists) {
                  // Show the confirmation modal
                  const dialogService = (0, _application.getOwner)(this).lookup("service:dialog");
                  dialogService.yesNoConfirm({
                    message: "Are you sure you want to overwrite your existing review?",
                    didConfirm: () => {
                      // Proceed with deleting the existing review and creating a new one
                      $.ajax({
                        type: "POST",
                        url: "/rate_seller",
                        data: {
                          username,
                          content,
                          ratings,
                          seller,
                          overwrite: true // Add this parameter to indicate overwrite
                        }
                      }).then(() => {
                        composerController.close();
                        _url.default.routeTo(`/u/${seller}/reviews`);
                        resolve();
                      }).fail(jqXHR => {
                        const error = jqXHR.responseJSON.error || "An error occurred";
                        (0, _application.getOwner)(this).lookup("service:modal").show(_rateError.default, {
                          model: {
                            errorMessage: error,
                            isVisible: true
                          }
                        });
                        reject(error);
                      });
                    },
                    onCancel: () => {
                      // Do nothing, just reject the promise
                      reject("User canceled overwrite");
                    }
                  });
                } else {
                  composerController.close();
                  _url.default.routeTo(`/u/${seller}/reviews`);
                  resolve();
                }
              }).fail(jqXHR => {
                const error = jqXHR.responseJSON.error;
                (0, _application.getOwner)(this).lookup("service:modal").show(_rateError.default, {
                  model: {
                    errorMessage: error,
                    isVisible: true
                  }
                });
                reject(error);
              });
            });
          }
          if (categoryId === siteSettings.buyer_category) {
            const content = composerModel.reply;
            const seller = composerModel.title.match(/@(\w+)/)[1];
            const username = composerModel.user.username;
            const ratings = composerModel.ratingsString;
            return new Promise((resolve, reject) => {
              $.ajax({
                type: "POST",
                url: "/rate_buyer",
                data: {
                  username,
                  content,
                  ratings,
                  seller,
                  check_existing: true // Add this parameter to check for existing reviews
                }
              }).then(response => {
                if (response.exists) {
                  // Show the confirmation modal
                  const dialogService = (0, _application.getOwner)(this).lookup("service:dialog");
                  dialogService.yesNoConfirm({
                    message: "Are you sure you want to overwrite your existing review?",
                    didConfirm: () => {
                      // Proceed with deleting the existing review and creating a new one
                      $.ajax({
                        type: "POST",
                        url: "/rate_buyer",
                        data: {
                          username,
                          content,
                          ratings,
                          seller,
                          overwrite: true // Add this parameter to indicate overwrite
                        }
                      }).then(() => {
                        composerController.close();
                        _url.default.routeTo(`/u/${seller}/buyers`);
                        resolve();
                      }).fail(jqXHR => {
                        const error = jqXHR.responseJSON.error || "An error occurred";
                        (0, _application.getOwner)(this).lookup("service:modal").show(_rateError.default, {
                          model: {
                            errorMessage: error,
                            isVisible: true
                          }
                        });
                        reject(error);
                      });
                    },
                    onCancel: () => {
                      // Do nothing, just reject the promise
                      reject("User canceled overwrite");
                    }
                  });
                } else {
                  composerController.close();
                  _url.default.routeTo(`/u/${seller}/buyers`);
                  resolve();
                }
              }).fail(jqXHR => {
                const error = jqXHR.responseJSON.error;
                (0, _application.getOwner)(this).lookup("service:modal").show(_rateError.default, {
                  model: {
                    errorMessage: error,
                    isVisible: true
                  }
                });
                reject(error);
              });
            });
          }
          return Promise.resolve();
        });
        api.removePostMenuButton('reply', attrs => {
          if ((attrs?.topic?.category_id ?? null) == siteSettings.buyer_category || (attrs?.topic?.category_id ?? null) == siteSettings.seller_category) {
            return true;
          }
        });
        api.onPageChange(() => {
          const topicController = api.container.lookup("controller:topic");
          if (topicController.model) {
            const currentCategory = topicController.model.category_id.toString();
            if (currentCategory === siteSettings.buyer_category || currentCategory === siteSettings.seller_category) {
              // Hide reply button for category 33
              const style = document.createElement("style");
              style.innerHTML = `
            .topic-footer-main-buttons .create {
              display: none !important;
            }
          `;
              document.head.appendChild(style);
            }
          }
        });
      });
    }
  };
});
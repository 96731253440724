define("discourse/plugins/discourse-ratings-custom-version/discourse/connectors/composer-fields/composer-controls-rating", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      updateRating(rating) {
        const ratings = this.get("model.ratings") || [];
        const index = ratings.findIndex(r => r.type === rating.type);
        (0, _object.set)(ratings[index], "value", rating.value);
        this.set("model.ratings", ratings);
      }
    }
  };
});
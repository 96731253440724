define("discourse/plugins/discourse-ratings-custom-version/discourse/components/rating-migrate", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings-custom-version/discourse/models/rating", "@ember/component"], function (_exports, _decorators, _rating, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noneType = "none";
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["rating-action-controls"],
    migrateDisabled(categoryId, toType, fromType) {
      return [categoryId, toType, fromType].any(i => !i) || toType !== noneType && fromType !== noneType;
    },
    actions: {
      migrate() {
        let data = {
          category_id: this.categoryId,
          type: this.fromType,
          new_type: this.toType
        };
        this.set("startingMigration", true);
        _rating.default.migrate(data).then(result => {
          if (result.success) {
            this.set("migrationMessage", "admin.ratings.migrate.started");
          } else {
            this.set("migrationMessage", "admin.ratings.error.migration_failed_to_start");
          }
        }).finally(() => this.set("startingMigration", false));
      }
    }
  }, [["method", "migrateDisabled", [(0, _decorators.default)("categoryId", "toType", "fromType")]]]));
});
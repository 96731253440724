define("discourse/plugins/discourse-ratings-custom-version/discourse/components/topic-rating-tip", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "topic-rating-tip",
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      let $element = $(this.element);
      let $target = $(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  });
});